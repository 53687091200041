import {
  getDefaultTheme,
  isDarkMode,
} from '../../utils/functions/themeFunctions'

import LightLogo from '../../assets/img/logo-light.svg'
import DarkLogo from '../../assets/img/logo-dark.svg'

import './LoadingPage.css'

const LoadingPage = () => {
  const theme = getDefaultTheme()

  return (
    <div className='loading-page'>
      <img
        alt='Loading splash'
        height={80}
        src={isDarkMode(theme) ? DarkLogo : LightLogo}
      />
    </div>
  )
}

export default LoadingPage
