import { useThemeSwitcher } from 'react-css-theme-switcher'

export const persistTheme = (theme) => localStorage.setItem('theme', theme)

export const getDefaultTheme = () => localStorage.getItem('theme')

export const isDarkMode = (theme) => theme === 'dark'

export const useIsDarkMode = () => {
  const { currentTheme } = useThemeSwitcher()
  return isDarkMode(currentTheme)
}
